<script setup>
const config = useRuntimeConfig()
defineProps({
  title: {
    type: String,
    default: 'Liste des arrêts',
  },
  pending: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <div class="app-article-header">
    <SkeletonArticleHeader v-if="pending" />
    <div v-else class="flex justify-between mb-12 items-center space-x-2">
      <h2>
        <slot />
      </h2>
      <NuxtLink
        target="_blank"
        :to="`${config.public.vmcvAppUrl}?stop=${title}`"
        class="cta"
      >
        <SvgoIconMap class="w-4 h-4 float-left inline" />
        <span>Voir la carte</span>
        <SvgoIconArrowRight
          class="w-6 h-6 float-right inline fill-vmcv-blue-dark"
        />
      </NuxtLink>
    </div>
  </div>
</template>
