<template>
  <div class="w-full flex flex-col">
    <div
      class="w-full flex justify-between space-x-8 py-6 border-t border-t-gray-200"
    >
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-36 md:w-64" />
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-24 md:w-48" />
    </div>
    <div class="w-full flex justify-between py-6 border-t border-t-gray-200">
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-36 md:w-64" />
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-24 md:w-48" />
    </div>
    <div class="w-full flex justify-between py-6 border-t border-t-gray-200">
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-36 md:w-64" />
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-24 md:w-48" />
    </div>
  </div>
</template>
